import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const LinkMenu = styled(Link)`
  color: #fff !important;
  text-decoration: none;
`

export const Footer = styled.span`
  color: #fff;
  text-decoration: none;
  position: absolute;
  bottom: 2rem;
  align-self: center;
  font-weight: normal;
  font-size: 0.75rem;
  margin: 0.5rem 0.75rem;
  text-align: center;
  border-top: 0.5px solid #fff;
  padding-top: 0.75rem;
`

export const FooterLink = styled.a`
  color: #90caf9;
  text-decoration: none;
  align-self: center;
  font-weight: normal;
  font-size: 0.75rem;
`
