import React, { useState } from 'react'

// SERVICES
import {
  uploadTest
} from '@services/payment/paymentService'

// HELPERS
import * as Alert from '@helpers/alerts'

// COMPONENTS
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Input
} from '@mui/material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { AttachFile as FileIcon } from '@mui/icons-material'

// STYLES
import * as Styled from './styles'

const TestPage = (): JSX.Element => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)

  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setUploadedFile(event.target.files[0])
    }
  };

  
  const handleSubmit = async (e): Promise<void> => {
    e.preventDefault()

    try {
      const formData = new FormData()
      formData.append('file', uploadedFile)

      await uploadTest(formData)

      Alert.success(
        'Upload realizado com sucesso'
      )
      setTimeout(function(){
        window.location.reload();
     }, 2000)
    } catch {
      Alert.error('Ocorreu um erro ao realizar o upload')
    }
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title="Teste de Upload">
        <FileIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  return (
    <Styled.Container>
      <Box>
        <Card sx={{ width: 600 }}>
          <CardHeader title={renderCardHeader()} />
          <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input type="file" onChange={handleFileInput}/>
              </Grid>
              <Grid item xs={12}>
                <Styled.CustomButton variant="contained" type="submit">
                  Buscar
                </Styled.CustomButton>
              </Grid>
            </Grid>
          </form>
          </CardContent>
        </Card>
      </Box>
    </Styled.Container>
  )
}

export { TestPage }
