import { SchedulesErrorList } from '@components/SchedulesErrorList/SchedulesErrorList'
import { Welcome } from '@components/shared/Welcome/Welcome'
import { TestPage } from '@components/TestPage/TestPage'

type Route = {
  component: JSX.Element
  exact: boolean
  path: string
}

const routes = (): Route[] => [
  {
    component: <SchedulesErrorList />,
    exact: true,
    path: '/apps/payment/schedules/errors/list/',
  },
  {
    component: <Welcome />,
    exact: true,
    path: '/apps/payment/',
  },
  {
    component: <TestPage />,
    exact: true,
    path: '/apps/payment/test/upload',
  }
]

export default routes
