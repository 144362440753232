import styled from 'styled-components'
import { Button } from '@mui/material'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const CustomButton = styled(Button)`
  height: 100%;
`
