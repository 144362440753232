import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'

// COMPONENTS
import {
  Button,
  Card,
  Grid,
  TextField,
  Select,
  MenuItem,
  Box,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { AssignmentRounded as AssignmentRoundedIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { NotFound } from '@components/shared/NotFound/NotFound'
import { Loader } from '@components/Loader/Loader'

// HELPERS
import { formatDate } from '@helpers/functions'

// SERVICES
import { getSchedulesWithError, reprocessSchedule, deleteSchedule } from '@services/payment/paymentService'

// TYPES
import { GetSchedulesWithErrorResponse, Schedule } from '@shared/types/paymentTypes'

// STYLES
import * as Styled from './styles'

const SchedulesErrorList = (): JSX.Element => {
  const [schedules, setSchedules] = useState<Schedule[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [rowCount, setRowCount] = useState(0)
  const [searchParams, setSearchParams] = useState({
    searchType: 'gatewaySubscription',
    searchText: '',
  })

  useEffect(() => {
    const queryParams = {
      page: 1,
      limit,
    }
    _getSchedulesWithError(queryParams)
  }, [])

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.25,
      minWidth: 40,
      disableColumnMenu: true,
      sortable: false
    },
    {
      field: 'accountId',
      headerName: 'Account ID',
      flex: 1,
      minWidth: 250,
      disableColumnMenu: true,
      sortable: false
    },
    {
      field: 'gatewaySubscription',
      headerName: 'Gateway Subscription ID',
      flex: 0.5,
      minWidth: 100,
      disableColumnMenu: true,
      sortable: false
    },
    {
      field: 'type',
      headerName: 'Tipo agendamento',
      flex: 0.5,
      minWidth: 125,
      disableColumnMenu: true,
      sortable: false
    },
    {
      field: 'scheduleDate',
      headerName: 'Data agendamento',
      flex: 0.5,
      minWidth: 100,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) =>
        formatDate(params.row.scheduleDate, 'dd/MM/yyyy'),
    },
    {
      field: 'actions',
      headerName: 'Ações',
      flex: 0.5,
      minWidth: 220,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => renderActionButtons(params.row.id),
    },
  ]

  const _getSchedulesWithError = async (queryParams): Promise<void> => {
    try {
      const response: GetSchedulesWithErrorResponse = await getSchedulesWithError(
        queryParams
      )

      setSchedules(response.results)
      setRowCount(response.pagination.totalItems)
      setCurrentPage(response.pagination.currentPage)

      
    } finally {
      setIsLoading(false)
    }
  }

  const _reprocessSchedule = async (id: number): Promise<void> => {
    Swal.fire({
      title: 'Tem certeza?',
      text: "Esta ação irá reprocessar o agendamento!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#1976d2',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Voltar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoading(true)
          
          await reprocessSchedule(id)

          Swal.fire({
            title: 'Agendamento reprocessado com sucesso',
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: '#1976d2',
            didClose: (): void => window.location.reload()
          })
        } catch(e) {
          let errorMsg = 'Ocorreu um erro ao reprocessar o agendamento.'
          const errorObj = e.errors[0]?.err ? JSON.parse(e.errors[0]?.err) : undefined

          if (errorObj && errorObj?.response?.errors[0]?.message) {
            errorMsg = errorObj?.response?.errors[0]?.message
          }

          Swal.fire({
            title: errorMsg,
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: '#1976d2',
          })
        } finally {
          setIsLoading(false)
        }
      }
    })
  }

  const _deleteSchedule = async (id: number): Promise<void> => {
    Swal.fire({
      title: 'Tem certeza?',
      text: "Esta ação irá excluir o agendamento!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#1976d2',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Voltar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoading(true)
          
          await deleteSchedule(id)

          Swal.fire({
            title: 'Agendamento removido com sucesso!',
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: '#1976d2',
            didClose: (): void => window.location.reload()
          })
        } catch {
          Swal.fire({
            title: 'Ocorreu um erro ao remover o agendamento.',
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: '#1976d2',
          })
        } finally {
          setIsLoading(false)
        }
      }
    })
  }

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    const queryParams = {
      page: currentPage,
      limit,
      ...(searchParams.searchText
        ? { [searchParams.searchType]: searchParams.searchText }
        : {}),
    }
    _getSchedulesWithError(queryParams)
  }

  const handlePageSizeChange = (newSize) => {
    setLimit(newSize)

    const queryParams = {
      page: currentPage,
      limit: newSize,
      ...(searchParams.searchText
        ? { [searchParams.searchType]: searchParams.searchText }
        : {}),
    }
    _getSchedulesWithError(queryParams)
  }

  const renderActionButtons = (id: number): JSX.Element => {
    return (
      <>
        <Button
          onClick={(): Promise<void> => _reprocessSchedule(id)}
          color="primary"
          variant="contained"
          sx={{ mr: 1 }}
        >
          Reprocessar
        </Button>
        <Button
          onClick={(): Promise<void> => _deleteSchedule(id)}
          color="error"
          variant="contained"
        >
          Remover
        </Button>
      </>
    )
  }

  if (isLoading) {
    return <Loader type="overlay" />
  }

  return (
    <Styled.ScheduleErrorListWrapper>
      <HeaderTitle title="Lista de agendamentos com erro">
        <AssignmentRoundedIcon fontSize="large" />
      </HeaderTitle>
      <Styled.FullWidthForm onSubmit={handleSearchSubmit}>
        <Grid container mt={4} columnSpacing={2}>
          <Grid item xs={3}>
            <Select
              value={searchParams.searchType}
              fullWidth
              size={'small'}
              onChange={(e) =>
                setSearchParams({
                  searchType: e.target.value,
                  searchText: '',
                })
              }
            >
              <MenuItem value={'gatewaySubscription'}>Gateway Subscription ID</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={7} lg={8}>
            <TextField
              label="Buscar"
              variant="outlined"
              fullWidth
              size={'small'}
              onChange={(e) =>
                setSearchParams({
                  ...searchParams,
                  searchText: e.target.value,
                })
              }
              value={searchParams.searchText}
            />
          </Grid>
          <Grid item xs={2} lg={1}>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ height: '100%' }}
            >
              <Styled.CustomButton variant="contained" type="submit">
                Buscar
              </Styled.CustomButton>
            </Box>
          </Grid>
        </Grid>
      </Styled.FullWidthForm>
      {schedules?.length > 0 ? (
        <>
          <Card
            sx={{
              mt: 4,
              ['& .MuiDataGrid-iconSeparator']: {
                display: 'none',
              },
            }}
          >
            <DataGrid
              paginationMode="server"
              rowCount={rowCount}
              rows={schedules}
              columns={columns}
              pageSize={limit}
              onPageSizeChange={(newPageSize) =>
                handlePageSizeChange(newPageSize)
              }
              rowsPerPageOptions={[10, 25, 50]}
              autoHeight
              disableSelectionOnClick
              sx={{
                ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
                  outline: 'none',
                },
              }}
              onPageChange={(page): Promise<void> =>
                _getSchedulesWithError({
                  page: page + 1,
                  limit,
                  ...(searchParams.searchText
                    ? {
                        [searchParams.searchType]:
                          searchParams.searchText,
                      }
                    : {}),
                })
              }
            />
          </Card>
        </>
      ) : (
        <NotFound title="Ops... Não encontramos nenhum agendamento com erro." />
      )}
    </Styled.ScheduleErrorListWrapper>
  )
}

export { SchedulesErrorList }
