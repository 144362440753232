// API
import { paymentApi } from '@shared/api/payment/paymentApi'

// TYPES
import {
  GetSchedulesWithErrorResponse
} from '@shared/types/paymentTypes'

const getSchedulesWithError = (data?): Promise<GetSchedulesWithErrorResponse> => {
  const queryString = data ? `?${new URLSearchParams(data).toString()}` : ''
  return paymentApi.get(`/backoffice/subscriptionSchedule${queryString}`)
}

const reprocessSchedule = (id: number): Promise<object> => {
  return paymentApi.post(`/backoffice/subscriptionSchedule/process/${id}`)
}

const deleteSchedule = (id: number): Promise<object> => {
  return paymentApi.post(`/backoffice/subscriptionSchedule/cancel/${id}`)
}

const uploadTest = (data): Promise<object> => {
  return paymentApi.post(`/backoffice/upload`, data , {
    headers: {
      'Content-Type': 'multipart/form-data'
    }})
}

export { getSchedulesWithError, reprocessSchedule, deleteSchedule, uploadTest }
