import { useState } from 'react'
import {
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Collapse,
} from '@mui/material'

import {
  ExpandLess,
  ExpandMore,
  ErrorOutlineOutlined as ErrorOutlineOutlinedIcon,
  CardMembership as CardMembershipIcon,
  AttachFile as FileIcon,
  PrecisionManufacturing as TestIcon
} from '@mui/icons-material'

// STYLES
import * as Styled from './styles'

const SideBar = (): JSX.Element => {
  const drawerWidth = 220

  const menuItems = [
    {
      id: 2,
      name: 'Assinaturas',
      icon: <CardMembershipIcon />,
      subItems: [
        {
          name: 'Agendamentos com erro',
          link: '/apps/payment/schedules/errors/list',
          icon: <ErrorOutlineOutlinedIcon />,
        },
      ],
    },
    {
      id: 3,
      name: 'Testes',
      icon: <TestIcon />,
      subItems: [{
        name: 'Upload',
        link: '/apps/payment/test/upload',
        icon: <FileIcon />,
      },],
    }
  ]

  const [isMenuVisible, setIsMenuVisible] = useState(
    menuItems.reduce(
      (menuItem, { id }) => ({
        ...menuItem,
        [id]: false,
      }),
      {}
    )
  )

  const handleClick = (id: number): void => {
    setIsMenuVisible({
      ...isMenuVisible,
      [id]: !isMenuVisible[id],
    })
  }

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        ['& .MuiDrawer-paper']: {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: 'primary.main',
        },
      }}
    >
      <Toolbar sx={{ mt: 2 }} />
      {menuItems.map((item, itemsIndex) => (
        <List component="nav" key={itemsIndex} disablePadding>
          <ListItemButton onClick={(): void => handleClick(item.id)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              primary={item.name}
              disableTypography
              sx={{ fontWeight: '300', fontSize: '0.875rem' }}
            />
            {isMenuVisible[item.id] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={isMenuVisible[item.id]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.subItems.map((subItem, subItemIndex) => (
                <Styled.LinkMenu key={subItemIndex} to={subItem.link}>
                  <ListItem button key={subItem.name} sx={{ pl: 4 }}>
                    <ListItemIcon>{subItem.icon}</ListItemIcon>
                    <ListItemText
                      primary={subItem.name}
                      disableTypography
                      sx={{ fontWeight: '300', fontSize: '0.875rem' }}
                    />
                  </ListItem>
                </Styled.LinkMenu>
              ))}
            </List>
          </Collapse>
        </List>
      ))}
      <Styled.Footer>
        &copy; {new Date().getFullYear()}{' '}
        <Styled.FooterLink href="http://gamersclub.com.br" target="_blank">
          GamersClub
        </Styled.FooterLink>{' '}
        made with ❤️ for a better backoffice
      </Styled.Footer>
    </Drawer>
  )
}

export { SideBar }
